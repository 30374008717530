import React, {useEffect, useRef, useState} from 'react';
import { useNavigate} from 'react-router-dom';
import {Input, Button, message, Watermark, Row, Col, Spin} from "antd";
import axios from 'axios';
import Logo from "../../assets/pictures/truck.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';
import {useWindowDimensions} from "../../contexts/WindowDimensionsContext";
import {Flex} from "rebass";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const VerifyEmail = ({ backendApiUrl }) => {
    const query = useQuery();
    const token = query.get("token");
    const navigate = useNavigate();
    const windowDimensions = useWindowDimensions()
    const [status, setStatus] = useState("pending")

    useEffect(() => {
        if (token) {
            handleVerifyEmail();
        }
    }, [token]);

    const handleVerifyEmail = async () => {
        try {
            if (!backendApiUrl) {
                console.error("backendApiUrl is missing.");
                setStatus("error");
                return;
            }

            const response = await axios.get(`${backendApiUrl}/user/verify-email/${token}`, {
                headers: { "Content-Type": "application/json" }
            });

            setStatus(response.data.success ? "success" : "error");
        } catch (error) {
            console.error("Verification failed:", error);
            setStatus("error");
            message.error("Une erreur est survenue. Veuillez réessayer.");
        }
    };

    return (
        <Watermark zIndex={-1} content="Team Kimmel">
            <div style={{ height: windowDimensions.windowHeight }} className={"overflow-auto"}>
                <div className={"overflow-auto"}>
                    <Row className={`justify-content-center align-items-center mx-2 overflow-auto`} style={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Col
                            className={windowDimensions.wWCheck(700) ? "mb-32" : ""}
                            style={{
                                background: "var(--White)",
                                padding: windowDimensions.wWCheck(700) ? "35px" : "20px",
                                borderRadius: "15px",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                minWidth: windowDimensions.wWCheck(700) ? "500px" : "400px",
                                width: "100%",
                                overflowY: "auto"
                            }}
                            xs={12} sm={7} md={6} lg={4} xl={3}
                        >
                            <div className="text-center mb-6">
                                <h1 className="text-1500 text-3xl">Intranet Team Kimmel</h1>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mb-6">
                                <img src={Logo} alt="Team Kimmel" className="reset-password-logo" style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))", borderRadius: "10px", maxWidth: windowDimensions.wWCheck(700) ? '350px' : "250px"}}/>
                            </div>
                            <h3 className="text-1500 text-2xl text-custom-blue text-center">Vérification de votre adresse mail</h3>
                            <p className="text-700 mt-0.5 text-center mb-5">Votre adresse mail va être vérifiée par le système</p>

                            {status === "pending" &&
                                <Flex justifyContent={"center"} alignItems={"center"}>
                                    <p className={"text-center mr-5"}>Veuillez patienter pendant le processus s'il vous plaît.</p>
                                    <Spin />
                                </Flex>
                            }
                            {status === "success" &&
                                <Flex justifyContent={"center"} alignItems={"center"}>
                                    <p className={"mr-2"}>Votre adresse email à bien été validée, vous pouvez désormais vous connecter à l'Intranet de la Team Kimmel.</p>
                                    <CheckOutlined style={{ fontSize: "40px", color: "#52c41a" }} />
                                </Flex>
                            }

                            {status === "error" &&
                                <Flex justifyContent={"center"} alignItems={"center"}>
                                    <p className={"mr-2"}>Votre adresse email n'a pas pu être vérifée, veillez à utiliser le dernier email contenant le dernier lien de vérification de votre email.</p>
                                    <CloseOutlined style={{ fontSize: "40px", color: "#ff4d4f" }} />
                                </Flex>
                            }

                            {status === "success" &&
                                <Button
                                    type="primary"
                                    className="w-100 mt-6"
                                    onClick={() => navigate("/")}
                                >
                                    Vous connecter
                                </Button>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </Watermark>
    );
};

export default VerifyEmail;
